<template>
  <div class="shouyi">
    <!-- <title-top>收益</title-top> -->

    <a class="db pr con bgw">
      头像
      <span class="r set-head" @click.stop="uploadHeadImg">
        <img class="set-head-icon" :src="avatar" style="width: 40px;height: 40px;" />
      </span>
      <input type="file" accept="image/*" @change="handleFile" class="hiddenInput dn" />
    </a>
  </div>
</template>

<script>
// @ is an alias to /src
//https://www.jianshu.com/p/fee4420a9515
export default {
  name: "SzNc",
  data() {
    return {
      avatar: ""
    };
  },
  watch: {
    avatar() {
      this.$http
        .get("api/set", { params: { avatar: this.avatar } })
        .then(res => {
          let j = res.data;
          if (j.code == 200) {
            this.avatar = j.data.avatar;
          }
        });
    }
  },
  mounted() {
    //require("./jquery.min");
  },
  // 打开图片上传
  uploadHeadImg: function() {
    this.$el.querySelector(".hiddenInput").click();
  },
  // 将头像显示
  handleFile: function(e) {
    let $target = e.target || e.srcElement;
    let file = $target.files[0];
    let param = new FormData(); //创建form对象
    param.append("file", file, file.name); //通过append向form对象添加数据
    param.append("chunk", "0"); //添加form表单中其他数据
    let config = {
      headers: { "Content-Type": "multipart/form-data" }
    }; //添加请求头
    this.$http.post("api/upload", param, config).then(res => {
      var j = res.data;
      if (j.code === 200) {
        this.avatar = j.data[0];
        this.$tip("头像上传成功！");
      }
    });
  }
};
</script>
<style  scoped>
.set-head {
  position: relative;
  top: 0.7em;
}
.set-head-icon {
  width: 2em;
  border-radius: 50%;
}
.dn {
  display: none;
}
</style>
